@tailwind base;
@tailwind components;
@tailwind utilities;
.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}

.loader {
  color: #2b328b;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0;
}

.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 3.5em;
}

@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

/*** Page Title ***/

.title-section {
  margin: 0 auto;
  width: 100%;
  position: relative;
  padding: 80px 0;
}

.title-section h1 {
  font-size: 56px;
  font-weight: 900 !important;
  color: #fff;
  text-transform: uppercase;
  margin: 0;
}

.title-bg {
  font-size: 110px;
  left: 0;
  letter-spacing: 10px;
  line-height: 0.7;
  position: absolute;
  right: 0%;
  top: 50%;
  text-transform: uppercase;
  font-weight: 800;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  color: rgba(221, 221, 221, 0.15);
}
html[dir="rtl"] .title-bg {
  right: -12%;
}
@media (max-width: 767px) {
  .title-section h1 {
    font-size: 36px;
  }
  .title-bg {
    font-size: 70px;
  }
}
@media (max-width: 480px) {
  .title-section h1 {
    font-size: 26px;
  }
  .title-bg {
    font-size: 50px;
  }
}
.client_shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
